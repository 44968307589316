import React from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    useBreakpointValue,
    useColorModeValue,
    Heading,
    Link,
    Stack,
    MenuButton,
    Menu,
    MenuItem,
    MenuList,
    Image,
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import ToggleTheme from "./ToggleTheme";

type Props = {}

export default function Header(props: Props) {
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    return (
        <Box as="section" pb={{ base: '2' }} borderWidth='1px' borderRadius='lg'>
            <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')} >
                <Container maxW='container.lg' py={{ base: '4', lg: '5' }}>
                    <HStack spacing="10" justify="space-between">
                        <HStack spacing="3" justify="space-between">
                        <Image src='/favicon.ico' alt='Logo' />
                        <Flex align="center" mr={5}>
                            
                            <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                                Xiangyu Shen
                            </Heading>
                        </Flex>
                        </HStack>
                        {isDesktop ? (
                            <Flex justify="space-between" flex="1">
                                <Stack
                                    direction={{ base: "column", md: "row" }}
                                    display={{ base: "block", md: "flex" }}
                                    width={{ base: "full", md: "auto" }}
                                    alignItems="center"
                                    flexGrow={1}
                                    mt={{ base: 4, md: 0 }}
                                >
                                    <Link href='/'>Home</Link>
                                    <Link href='/aboutme'>About Me</Link>
                                    <Link href='/portfolio'>Portfolio</Link>
                                </Stack>
                                <ToggleTheme text="icon" />
                            </Flex>
                        ) : (
                            <Menu>
                                <MenuButton as={Button}
                                    aria-label="Open Menu"
                                    px={4}
                                    py={2}
                                    transition='all 0.2s'
                                    borderRadius='md'
                                    borderWidth='1px'
                                    autoSelect={false}
                                    _hover={{ bg: 'gray.400' }}
                                    _expanded={{ bg: 'blue.700' }}
                                    _focus={{ boxShadow: 'outline' }}>
                                    <FiMenu fontSize="1.25rem" />
                                </MenuButton>
                                <MenuList>
                                    <Link href='/'><MenuItem>Home</MenuItem></Link>
                                    <Link href='/aboutme'><MenuItem>About Me</MenuItem></Link>
                                    <Link href='/portfolio'><MenuItem>Portfolio</MenuItem></Link>
                                    <Box alignSelf={"left"}>
                                        <ToggleTheme text="icon" />
                                    </Box>
                                </MenuList>
                            </Menu>
                        )}
                    </HStack>
                </Container>
            </Box>
        </Box>
    )
}
