import React, { ReactElement } from 'react'
import { Container } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SplitWithImage from '../components/SplitWithImage';

export default function Home(): ReactElement {

    return (
        <Container maxW='container.lg'>
            <Header />
            <SplitWithImage
                heading={"Xiangyu Shen"}
                subtitle={"Software and Data Engineer"}
                image={"/Xiangyu_ProfilePic.jpg"}/>
            <Footer />
        </Container>
    )
}
