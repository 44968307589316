import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  AiFillCode,
} from 'react-icons/ai';
import {
  DiDatabase,
} from 'react-icons/di'
import {
  BsFillFileEarmarkCodeFill,
  BsClipboardData,
} from 'react-icons/bs';
import { ReactElement } from 'react';

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

interface SplitProps {
  heading: string
  subtitle: string;
  image: string;
  text?: string
}

export default function SplitWithImage({ heading, subtitle, image, text }: SplitProps) {

  let textBg = useColorModeValue('blue.50', 'blue.900')

  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Flex>
          <Image
            rounded={'md'}
            alt={'Portrait Photo'}
            src={
              image
            }
            objectFit={'cover'}
          />
        </Flex>
        <Stack spacing={4}>
          <Heading>{heading}</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            {subtitle}
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
            <Feature
              icon={
                <Icon as={AiFillCode} color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Software Engineering'}
            />
            <Feature
              icon={<Icon as={BsFillFileEarmarkCodeFill} color={'green.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Web Developer'}
            />
            <Feature
              icon={<Icon as={DiDatabase} color={'blue.500'} w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text={'API and Database Developer'}
            />
            <Feature
              icon={
                <Icon as={BsClipboardData} color={'purple.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Data Visualization and Analysis'}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
      {text && <Text
        border='1px'
        borderColor={textBg}
        color={'black'}
        fontWeight={600}
        fontSize={'sm'}
        p={2}
        alignSelf={'flex-start'}
        rounded={'md'}
        backgroundColor={'white'}>
        {text}
      </Text>}
    </Container>
  );
}