import React, { ReactElement } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import Home from '../pages/Home';
import AboutMe from '../pages/AboutMe';
import Portfolio from '../pages/Portfolio';
import NotFound from '../pages/NotFound';

export default function Navigation(): ReactElement {
      
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutme" element={<AboutMe />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    )
}
