import { ButtonGroup, Container, IconButton, Stack, Text, Center } from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

export default function Footer() {
    return (
        <Container maxW='container.lg' as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
            <Stack spacing={{ base: '4', md: '5' }}>
                <Center>
                    <ButtonGroup variant="ghost">
                        <IconButton
                            as="a"
                            href="https://www.linkedin.com/in/xiangyushen/"
                            aria-label="LinkedIn"
                            icon={<FaLinkedin fontSize="1.25rem" />}
                        />
                        <IconButton
                            as="a"
                            href="https://github.com/XiangyuShen"
                            aria-label="GitHub"
                            icon={<FaGithub fontSize="1.25rem" />} />
                        <IconButton
                            as="a"
                            href="https://twitter.com/Xiangyu_Shen"
                            aria-label="Twitter"
                            icon={<FaTwitter fontSize="1.25rem" />}
                        />
                    </ButtonGroup>
                </Center>
                <Center>
                    <Text fontSize="sm" color="subtle">
                        &copy; {new Date().getFullYear()} Xiangyu Shen.
                    </Text>
                </Center>
            </Stack>
        </Container>
    )
}