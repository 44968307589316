import React, { ReactElement } from 'react'
import { Text, Container } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export default function AboutMe(): ReactElement {

    return (
        <Container maxW='container.lg'>
            <Header />
            <Text>{`
                Hello, I'm Xiangyu Shen. I'm a software engineer, data analyst, and web developer.
                
                I make applications, frontends websites, backend APIs and databases, and create data visualizations and analysis.

                See some of my work `}<Link to='/portfolio'>here</Link>.
            </Text>
            <Footer />
        </Container>
    )
}
