import React, { ReactElement } from 'react'
import { Text, Container, Link } from '@chakra-ui/react';

export default function Home(): ReactElement {

    return (
        <Container>
            <Text pt={10} align='center'>
                404 - Page Not Found.
            <br />
                Return to the{' '}
                <Link color='teal.500' href='/'>
                    home page
                </Link>.
            </Text>
        </Container>
    )
}
