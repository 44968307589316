import { Center, Button} from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/color-mode";

type Props = {
    text: any
}

function ToggleTheme( { text } : Props) {
    const { colorMode, toggleColorMode } = useColorMode();
    const light = text === "text" ? "Light": "☼";
    const dark = text === "text" ? "Dark": "☾";
    return (
        <Center h="50px">
            <Button onClick={toggleColorMode} variant="outline"
          _hover={{ bg: 'gray.400', borderColor: 'gray.400' }}>
                {colorMode === "light" ? dark : light}
            </Button>
        </Center>
    );
}

export default ToggleTheme;