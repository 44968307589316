import Navigation from './navigation/Navigation';
import { Container } from '@chakra-ui/react';

function App() {
  return (
    <Container maxW='container.lg'>
      <Navigation />
    </Container>
  );
}

export default App;