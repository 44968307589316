import React, { ReactElement } from 'react'
import { Text, Container } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export default function Portfolio(): ReactElement {

    return (
        <Container maxW='container.lg'>
            <Header />
            <Text>
                This is my portfolio.

                Data visualization and analysis projects:

                Jobs:
                <Link to={'https://www.responsiblealpha.com/dashboards'}>Responsible Alpha</Link>

                Class Projects:
                <Link to={'https://laytat.github.io/hciProjectII/'}>Can Money Really Buy Happiness?</Link>
                A Study on Wealth Inequality
            </Text>
            <Footer />
        </Container>
    )
}
